import request from '@/utils/request'

// 发布行程
export function release(data) {
    return request({
        url: '/carpooling/release',
        method: 'post',
        data: data,
        loading: true
    })
}

// 行程详情
export function detail(data) {
    return request({
        url: '/carpooling/detail',
        method: 'post',
        data: data,
        loading: true
    })
}

// 行程详情by invite
export function detailByInvite(data) {
    return request({
        url: '/carpooling/detailByInvite',
        method: 'post',
        data: data,
        loading: true
    })
}

// 行程列表
export function lists(data) {
    return request({
        url: '/carpooling/getList',
        method: 'post',
        data: data
    })
}

// 行程列表
export function lists2(data) {
    return request({
        url: '/carpooling/getList2',
        method: 'post',
        data: data
    })
}

// 订单列表
export function getOrderLists(data) {
    return request({
        url: '/order/getList',
        method: 'post',
        data: data
    })
}

// 删除行程
export function deleteCarpool(data) {
    return request({
        url: '/carpooling/delete',
        method: 'post',
        data: data
    })
}

// 删除订单
export function deleteOrder(data) {
    return request({
        url: '/order/delete',
        method: 'post',
        data: data
    })
}


// 最新行程
export function lastRelease(data) {
    return request({
        url: '/carpooling/last_release',
        method: 'post',
        data: data
    })
}

