import request from '@/utils/request'

// 发送验证码
export function sendsms(data) {
    return request({
        url: '/user/sendsms',
        method: 'post',
        data: data
    })
}

// 绑定手机号
export function bind(data) {
    return request({
        url: '/user/bind',
        method: 'post',
        data: data
    })
}

// 跳转
export function jumpAuth(data) {
    return request({
        url: '/wechat/oauth',
        method: 'post',
        data: data
    })
}