import axios from 'axios'
import { getToken,removeToken } from '@/utils/auth'
import cache from '@/plugins/cache'
import errorCode from '@/utils/errorCode'
import { useUserStore } from '@/stores/user'
import { redirectTo } from '@/utils/util'
import { useRouter } from 'vue-router'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: import.meta.env.VITE_BASE_API,
  // 超时
  timeout: 50000
})

// request拦截器
service.interceptors.request.use(config => {
  if(config.loading) {
    showLoadingToast({
      message: '加载中...',
      forbidClick: true,
    });
  }
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    if (getToken() && !isToken) {
      config.headers['token'] = getToken();
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
      const requestObj = {
        url: config.url,
        data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
        time: new Date().getTime()
      }
      const requestSize = Object.keys(JSON.stringify(requestObj)).length; // 请求数据大小
      const limitSize = 5 * 1024 * 1024; // 限制存放数据5M
      if (requestSize >= limitSize) {
        console.warn(`[${config.url}]: ` + '请求数据大小超出允许的5M限制，无法进行防重复提交验证。')
        return config;
      }
    }
    return config
  }, error => {
      console.log(error)
      Promise.reject(error)
  })

  // 响应拦截器
service.interceptors.response.use(res => {
    const user = useUserStore()
    closeToast
    // 未设置状态码则默认成功状态
    const code = res.data.code;

    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    
    // 二进制数据则直接返回
    if (res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer') {
        return res.data
    }
    if (code === 401) {
      user.LogOut()
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
        showNotify({ type: 'danger', message: msg });
      return Promise.reject(new Error(msg))
    } else if (code === 403) {
        showNotify({ type: 'danger', message: msg });
        return Promise.reject('error')
    } else if (code !== 1) {
        showFailToast(res.data.info);
        return Promise.reject('error')
    } else {
        return res.data
    }
  },
  error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    showNotify({ type: 'danger', message: message });
    return Promise.reject(error)
  }
)

export default service