<template>
<van-popup :show="show" position="bottom" :style="{ height: '100%',backgroundColor: '#f5f6fb' }"  >
    <div class="container">
        <van-nav-bar :title="title" style="color: var(--color);" @click-right="close">
            <template #right><van-icon name="close" color="var(--color)" size="24" /></template>
        </van-nav-bar>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</van-popup>
</template>


<script setup>
import { ref } from 'vue'
import { useUserStore } from '@/stores/user'

const user = useUserStore()
const props = defineProps(['title'])
const show = defineModel('show')

const close = () => {
    show.value = !show.value
    user.showModel = false
}

</script>

<style scoped lang="scss">
    .container {
        .header {
            height: 44px;
            background: #fff;
            box-shadow: 0 0 20px #eee;
            position: fixed;
            z-index: 10;
            left: 0;
            right: 0;
            padding: 0 5px;
            border-bottom: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h1 {
                flex: auto;
                color: #333;
                font-weight: 800;
                font-size: 17px;
                line-height: 44px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                text-align: center;
            }
            .close {
                width: 44px;
                height: 44px;
                text-align: center;
                display: flex;
                align-items: center;
            }
        }
    }
</style>