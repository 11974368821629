import request from '@/utils/request'

// 首页数据
export function app() {
    return request({
        url: '/init/home',
        method: 'post'
    })
}

// 推荐
export function recommend(data) {
    return request({
        url: '/carpooling/getRecommend',
        method: 'post',
        data: data
    })
}