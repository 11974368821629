export const cachePlugin = (store) => {
    const cache = new Map();
   
    store.$id = store.$id || store._id;
   
    // 保存状态到缓存
    store.$cache = function(state) {
      cache.set(this.$id, state);
    };
   
    // 从缓存中恢复状态
    store.$restore = function() {
      return cache.get(this.$id) || {};
    };
  };