<template>
    <div class="container">
        <div class="header">
            <div>
                <a class="return" v-if="back" href="javascript:;" @click="go"><van-icon name="arrow-left" color="var(--color)" size="21" /><span>{{back}}</span></a>
            </div>
            <h1>{{title}}</h1>
            <div class="right">
                <slot name="right"></slot>
                <div v-if="close"><a><van-icon name="close" color="var(--color)" size="24" /></a></div>
            </div>
        </div>
        <div class="content">
            <slot></slot>
        </div>
        <tabbar></tabbar>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import tabbar from '@/components/tabbar/navbar.vue'
    import popup from '@/components/popup/popup.vue'
    import { useRouter } from 'vue-router'
    

    const router = useRouter()

    const props = defineProps(['back','title','url','close'])
    
    const go = () => {
        console.log(props)
        props.url ? router.push(props.url) : router.go(-1) 
    }
</script>

<style scoped lang="scss">
    .container .header {
        height: 44px;
        background: #fff;
        box-shadow: 0 0 20px #eee;
        position: fixed;
        z-index: 10;
        left: 0;
        right: 0;
        padding: 0 5px;
        border-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
            flex: 1;
        }
        .return {
            display: flex;
            align-items: center;
            color: var(--color);
            font-size: 17px;
        }
        h1 {
            flex: 1;
            color: #333;
            font-weight: 800;
            font-size: 17px;
            line-height: 44px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-align: center;
        }
        .right {
            text-align: right;
        }
    }
    .container .content {
        padding-top: 44px;
        padding-bottom: 44px;
    }
</style>