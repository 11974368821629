<template>
    <van-tabbar active-color="#333" inactive-color="#333" route v-if="showFooter">
        <van-tabbar-item v-for="(item,index) in tabbar.list" :to="item.url">
            <span>{{item.title}}</span>
            <template #icon="props">
                <img :src="props.active ? item.selectIcon : item.icon" />
            </template>
        </van-tabbar-item>
    </van-tabbar>
</template>

<script setup>
    import { ref,computed } from 'vue'
    import { useRoute  } from 'vue-router'

    const route = useRoute()

    const showFooter = computed(() => {
        return ['/','/release','/user','/index'].includes(route.path)
    })

    // 导航栏
    const tabbar = ref({
        list: [
        {
            title: '首页',
            url: '/',
            icon: '/img/home.png',
            selectIcon: '/img/home_active.png'
        },
        {
            title: '车主发布',
            url: '/release',
            icon: '/img/edit.png',
            selectIcon: '/img/edit_active.png'
        },
        {
            title: '个人中心',
            url: '/user',
            icon: '/img/user.png',
            selectIcon: '/img/user_active.png'
        }
    ]
    })
</script>


<style scoped lang="scss"></style>