import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { jumpAuth } from '@/api/login/login'
import { info,getInfo } from '@/api/user'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: '',
      component:  () => import('@/views/index/IndexView.vue')
    },
    {
      path: '/index',
      name: 'index',
      component:  () => import('@/views/index/IndexView.vue')
    },
    {
      path: '/release/:id?',
      name: 'release',
      component: () => import('@/views/release/ReleaseView.vue'),
      meta: { auth: false }
    },
    {
      path: '/user',
      name: 'user',
      component: () => import('@/views/user/UserView.vue'),
    },
    // 授权登录
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/LoginView.vue')
    },
    {
      path: '/test',
      name: 'test',
      component: () => import('@/views/test/TestView.vue')
    },
    // 非微信内置浏览器
    {
      path: '/iswx',
      name: 'iswx',
      component: () => import('@/views/iswx/IsWxView.vue')
    },
    // 绑定
    {
      path: '/bind',
      name: 'bind',
      component: () => import('@/views/login/BindView.vue')
    },
    // 输入姓名
    {
      path: '/inputname',
      name: 'inputname',
      component: () => import('@/views/login/InputNameView.vue')
    },
    // 发布详情
    {
      path: '/release/detail/:id',
      name: 'releaseDetail',
      component: () => import('@/views/release/DetailView.vue')
    },
    // 拼车列表
    {
      path: '/carpool/list/:setout/:reach',
      name: 'carpoolList',
      component: () => import('@/views/list/IndexView.vue')
    },
    // 实名认证
    {
      path: '/user/real_name_auth/:is_authentication',
      name: 'real_name_auth',
      component: () => import('@/views/user/RealNameAuthView.vue')
    },
    // 车主认证
    {
      path: '/user/car_name_auth/:is_authCar',
      name: 'car_name_auth',
      component: () => import('@/views/user/CarAuthView.vue')
    },
    // 订单详情
    {
      path: '/order/detail/:id',
      name: 'order_detail',
      component: () => import('@/views/order/DetailView.vue')
    },
    // 修改手机号
    {
      path: '/user/modify_phone',
      name: 'modify_phone',
      component: () => import('@/views/user/ModifyPhoneView.vue')
    },
    // 修改姓名
    {
      path: '/user/modify_name',
      name: 'modify_name',
      component: () => import('@/views/user/ModifyNameView.vue')
    },
    // 帮助中心
    {
      path: '/help/index',
      name: 'help_index',
      component: () => import('@/views/help/IndexView.vue')
    },
    // 帮助中心二级
    {
      path: '/help/two/:id',
      name: 'help_two',
      component: () => import('@/views/help/IndexClassBView.vue')
    },
    // 帮助中心详细
    {
      path: '/help/detail/:id',
      name: 'help_detail',
      component: () => import('@/views/help/DetailView.vue')
    },
    // 投诉建议
    {
      path: '/help/complaint',
      name: 'help_complaint',
      component: () => import('@/views/help/ComplaintView.vue')
    },
    // 客服
    {
      path: '/help/kefu',
      name: 'help_kefu',
      component: () => import('@/views/help/KeFuView.vue')
    },
    // 我的行程
    {
      path: '/trip/index',
      name: 'trip_index',
      component: () => import('@/views/trip/IndexView.vue')
    },
    // 微信登录
    {
      path: '/wx/login',
      name: 'wx_login',
      component: () => import('@/views/login/WxLoginView.vue')
    },
    // 所有订单
    {
      path: '/carpool/orders/:id',
      name: 'carpool_orders',
      component: () => import('@/views/order/AllOrderView.vue')
    },
    // 诚信值
    {
      path: '/help/good_faith',
      name: 'good_faith',
      component: () => import('@/views/help/GoodFaithView.vue')
    },
    // 我的钱包
    {
      path: '/wallet',
      name: 'wallet',
      component: () => import('@/views/wallet/IndexView.vue')
    },
    // 审核
    {
      path: '/process',
      name: 'process',
      component: () => import('@/views/user/ProcessView.vue')
    },
    // 审核详细
    {
      path: '/process_detail/:id',
      name: 'process_detail',
      component: () => import('@/views/user/ProcessDetailView.vue')
    },
    // 审核详细
    {
      path: '/process_car_detail/:id',
      name: 'process_car_detail',
      component: () => import('@/views/user/ProcessCarDetailView.vue')
    },
    // 邀请
    {
      path: '/:invite',
      name: 'invite',
      component: () => import('@/views/invite/IndexView.vue')
    } 
  ]
})

// 设置路由前置守卫
router.beforeEach((to, from, next) => {
  useAppStore().Init()
    const user = useUserStore()
    // 非微信内置弹窗提醒
    if(navigator.userAgent.toLowerCase().indexOf('micromessenger') === -1) {
     // user.showModel = true
    }
    if(!user.isLogin && to.path !== '/wx/login') {
      // 登录授权
      if(to.query.code) {
        user.login({
            code: to.query.code,
            rcode: to.query.rcode,
            state: to.query.state
        }).then((rcode) => {
            window.location.href = rcode
        })
      } else {
        jumpAuth({redirect_url:window.location.origin + to.path,mode: 1}).then(res => {
            window.location.href = res.data.url
        })
      }
    } else {
      next()
    }
})

export default router
