export const useOnReady = () => {
    let readyResolve = null;

    const readyPromise = new Promise(resolve => {
        readyResolve = resolve
    })

    const onReady = (cd) => {
        readyPromise.then(() => {
            cd();
        })
    }

    return {
        onReady,
        readyResolve
    }
}