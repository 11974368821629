import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import Layout from '@/views/layout/IndexView.vue'
import { cachePlugin } from '@/utils/cachePlugin'
//import './assets/iconfont/iconfont.css'

const app = createApp(App)


const pinia = createPinia()
pinia.use(cachePlugin)
app.use(pinia)
app.use(router)


app.component('Layout',Layout)
app.mount('#app')
