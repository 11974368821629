import { defineStore } from 'pinia'
import { app } from '@/api'
import { getCookieLocation } from '@/utils/auth'
import { useOnReady } from "@/hooks/useOnReady";

const { readyResolve, onReady } = useOnReady()

export const useAppStore = defineStore('app', {
  state: () => ({
    location: getCookieLocation(),
    region: [],
    agreement: [],
    afee: [],
    hot: []
  }),
  getters: {

  },
  actions: {
    async Init() {
      await app().then(res => {
        this.region = res.data.region
        this.agreement = res.data.agreement
        this.afee = res.data.afee
        this.hot = res.data.hot
        readyResolve();
      })

    },
    getRegion(keyword) {
      return this.region.filter((item) => {
        return item.name.toLowerCase().includes(keyword.toLowerCase());
      });
    },
    getAgreement() {
      return app()
    }
  }
})

export const onAppStoreSetup = onReady;
