import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { info,getInfo, } from '@/api/user'
import { lastRelease } from '@/api/release'
import { useOnReady } from "@/hooks/useOnReady";
import { getToken, setToken, removeToken,getCity } from '@/utils/auth'
import { redirectTo } from '@/utils/util'
import { isEmpty, cloneDeep, clone } from 'lodash';

const { readyResolve, onReady } = useOnReady()

export const useUserStore = defineStore('user', {
  state: () => ({
    isLogin: !!getToken(),
    showModel: false,
    city: getCity(),
    userInfo: {},
    lastReleaseDatas: {}
  }),
  getters: {

  },
  actions: {
    // 登录处理
    login(data) {
      // 注册登录
      return new Promise((resolve, reject) => {
        getInfo(data).then(res => {
          this.SetToken(res.data.token)
          resolve(res.data.rcode)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 退出登录
    LogOut() {
      return new Promise((resolve, reject) => {
        this.SetToken()
        resolve()
      })
    },
    SetToken(token="") {
      if (token === '') {
        this.isLogin = false
        removeToken()
      } else {
        this.isLogin = true
        setToken(token)
      }
    },
    async getUserInfo() {
      this.userInfo = (await info()).data
    },
    // 获取最后一次发布的数据
    async getLastReleaseDatas() {
      await lastRelease().then(res => {
        this.lastReleaseDatas = res.data
      })
      // 请求完数据，执行readyResolve
      readyResolve();
    }
  }
})


export const onUserStoreSetup = onReady;