import request from '@/utils/request'

// 微信优雅授权登录获取code
export function oauth(query) {
    return request({
        url: '/wechat/api.test/oauth',
        method: 'get',
        loading: true
    })
}

// 授权登录获取用户信息
export function getInfo(query) {
    return request({
        url: '/wechat/oauth',
        method: 'post',
        params: query
    })
}

// 获取用户信息
export function info() {
    return request({
        url: '/user/info',
        method: 'post'
    })
}

// 获取详细地址by latlng
export function city(data)
{   
    return request({
        url: '/map/index',
        method: 'post',
        data: data
    })
}

// 根据关键字获取城市信息
export function keyword(data)
{   
    return request({
        url: '/map/keyword',
        method: 'post',
        data: data
    })
}

// 保存用户信息
export function saveUserInfo(data)
{
    return request({
        url: '/user/saveUserInfo',
        method: 'post',
        data: data
    })
}


// 上传文件
export function upload(formData)
{
    return request({
        url: '/user/upload',
        headers: {"Content-Type": "multipart/form-data;charset=UTF-8"},
        data: formData,
        method: 'post'
    })
}

// 实名认证
export function authentication(data)
{
    return request({
        url: '/user/authentication',
        method: 'post',
        data: data
    })
}

// 车人认证
export function carauth(data)
{
    return request({
        url: '/user/carauth',
        method: 'post',
        data: data
    })
}

// 获取实名信息
export function getAuthentication()
{
    return request({
        url: '/user/getAuthentication',
        method: 'post'
    })
}

// 获取驾驶证行驶证信息
export function getCarAuthentication()
{
    return request({
        url: '/user/getCarAuthentication',
        method: 'post'
    })
}

// 订单详情
export function getOrderDetail(data)
{
    return request({
        url: '/user/getOrderDetail',
        method: 'post',
        data: data
    })
}

// 发送手机短信
export function sendSms(data)
{
    return request({
        url: '/user/sms',
        method: 'post',
        data: data
    })
}

// 发送订单支付提醒短信
export function send_pay_notice(data)
{
    return request({
        url: '/user/send_pay_notice',
        method: 'post',
        data: data
    })
}



// 修改手机号
export function modifyMobile(data)
{
    return request({
        url: '/user/modifyMobile',
        method: 'post',
        data: data
    })
}

// 修改姓名
export function modifyName(data)
{
    return request({
        url: '/user/modifyName',
        method: 'post',
        data: data
    })
}

// 投诉建议
export function complaint_set(data)
{
    return request({
        url: '/complaint/set',
        method: 'post',
        data: data
    })
}


// 客服
export function getKefu(data)
{
    return request({
        url: '/help/getKefu',
        method: 'post',
        data: data
    })
}

// process
export function getProcessIdsCard(data)
{
    return request({
        url: '/process/getIdsCard',
        method: 'post',
        data: data
    })
}

// process
export function getCarAuth(data)
{
    return request({
        url: '/process/getCarAuth',
        method: 'post',
        data: data
    })
}

// process
export function getCarAuthDetail(data)
{
    return request({
        url: '/process/getCarDetail',
        method: 'post',
        data: data
    })
}

// processdetail
export function getIdsCardDetail(data)
{
    return request({
        url: '/process/getIdsCardDetail',
        method: 'post',
        data: data
    })
}


// set processdetail
export function setIdsCard(data)
{
    return request({
        url: '/process/setIdsCard',
        method: 'post',
        data: data
    })
}

export function setCarAuth(data)
{
    return request({
        url: '/process/setCarAuth',
        method: 'post',
        data: data
    })
}

export function cancel_carpool(data)
{
    return request({
        url: '/carpooling/cancel',
        method: 'post',
        data: data
    })
}






