<script setup>
import { onMounted } from 'vue'
import { RouterView } from 'vue-router'
import popup from '@/components/popup/popup.vue'
import { useUserStore } from '@/stores/user'
import { useAppStore, onAppStoreSetup } from '@/stores/app'

const user = useUserStore()
const app = useAppStore()

</script>

<template>
    <RouterView />
    <popup title="请先关注公众号" :show="user.showModel">
        <div class="popup">
            <p>长按关注公众号</p>
            <van-image class="img" width="258" height="258" src="/img/wxqrcode.jpg"/>
            <p style="text-align: center;margin-top: 10px;">微信公众号：他她出行</p>
        </div>
    </popup>
</template>

<style scoped lang="scss">
      .popup {
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            font-size: 14px;
            font-weight: normal;
            color: #8f8f94;
        }
        img {
            width: 258px;
            height: 258px;
            margin-top: 20px;
        }
    }
</style>
