import Cookies from 'js-cookie'

const TokenKey = 'Freeride-Token1'
const LocationKey = 'location'
const CityKey = 'city_dad2'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getCookieLocation()
{
  return Cookies.get(LocationKey)
}

export function setCookieLocation(location) {
  return Cookies.set(LocationKey, location)
}

export function getCity() {
  return Cookies.get(CityKey)
}
export function setCity(city) {
  return Cookies.set(CityKey, city)
}

